import { withRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import React from 'react';

// class ScrollTopOnRouteChange extends React.PureComponent<any, any> {
//   scrollTop = () => {
//     if (this.props.scrollTop) {
//       this.props.scrollTop();
//     }
//   };

//   componentDidMount = () => {
//     this.scrollTop();
//   };

//   componentDidUpdate = (prevProps: any) => {
//     console.log('============================================');
//     console.log('============================================');
//     console.log('============================================');
//     console.log(1, this.props.location !== prevProps.location);
//     console.log(2, this.props.location);
//     console.log(3, prevProps.location);
//     if (this.props.location !== prevProps.location) {
//       this.scrollTop();
//     }
//   };

//   render = () => this.props.children;
// }

// export default withRouter(ScrollTopOnRouteChange);


// const ScrollToTop = ({ children, location: { pathname } }) => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return children || null;
// };

// export default withRouter(ScrollToTop);


class ScrollToTop extends React.Component<any> {
  componentDidUpdate(prevProps: any) {
    if (this.props.location && this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
