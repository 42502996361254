import React, { PureComponent } from "react";

interface IProps {}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error | null, info: object) {
    this.setState({ hasError: true });
  }

  render() {
    const { children, ...restProps } = this.props as {
      children: React.ReactNode;
    };
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div>
          <p>Something went wrong. Please close the app and try again.</p>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
