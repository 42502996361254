import * as React from 'react';

export interface ISuspenseLoaderProps { }

export default class SuspenseLoader extends React.PureComponent<ISuspenseLoaderProps, any> {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#ffffff',
          zIndex: 9999,
          alignItems: 'center'
        }}>
        <h4>Loading ...</h4>
      </div>
    );
  }
}
