import { createActions } from "redux-actions";

import * as constants from "../constants/login";

export const {
  login,
  loginSuccess,
  loginError,
} = createActions(
  constants.LOGIN,
  constants.LOGIN_SUCCESS,
  constants.LOGIN_ERROR,
);
