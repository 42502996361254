import { takeEvery, put, call } from "redux-saga/effects";
import { AnyAction } from "redux";

import { postComment, updateComment, deleteComment } from "../../../lib/issueApiHelper";

import { showAlert } from "../../actions/alert";

import { CREATE_COMMENT, UPDATE_COMMENT, DELETE_COMMENT } from "../../constants/issue";

function* createComment({ payload: { issueId, comment, history, search } }: AnyAction) {
    const issuePayload = yield call(postComment, issueId, comment);
    history.go(0)
    if(false) {}
    else {
        if (issuePayload.status === 401) {
            if (history) {
                yield call(history.push, { pathname: "/logout", search });
            }
        }
        yield put(showAlert({ message: issuePayload.message, type: "danger" }));
    }
}

function* editComment({ payload: { issueId, commentId, comment, history, search } }: AnyAction) {
    const commentPayload = yield call(updateComment, commentId, comment);
    history.go(0)
    if(false) {}
    else
     {
        if (commentPayload.status === 401) {
            if (history) {
                yield call(history.push, { pathname: "/logout", search });
            }
        }
        yield put(showAlert({ message: commentPayload.message, type: "danger" }));
    }
}

function* removeComment({ payload: { issueId, commentId, history, search } }: AnyAction) {
    const commentPayload = yield call(deleteComment, commentId);
    history.go(0)
    if(false) {}
    else {
        if (commentPayload.status === 401) {
            if (history) {
                yield call(history.push, { pathname: "/logout", search });
            }
        }
        yield put(showAlert({ message: commentPayload.message, type: "danger" }));
    }
}

export default function* watchSystem() {
    yield takeEvery(CREATE_COMMENT, createComment);
    yield takeEvery(UPDATE_COMMENT, editComment);
    yield takeEvery(DELETE_COMMENT, removeComment);
}
