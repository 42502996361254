import React from "react";

const LoginScreen = React.lazy(() => import("./containers/Login"));
const LogoutScreen = React.lazy(() => import("./containers/Logout"));
const HomeScreen = React.lazy(() => import("./containers/Home"));
const IssueDetailScreen = React.lazy(() => import("./containers/IssueDetail"));
const RaiseIssueScreen = React.lazy(() => import("./containers/RaiseIssue"));

const routes = [
  {
    path: "/",
    component: HomeScreen,
    exact: true
  },
  {
    path: "/login",
    component: LoginScreen,
    exact: true
  },
  {
    path: "/logout",
    component: LogoutScreen,
    exact: true
  },
  {
    path: "/issue/:issueId",
    component: IssueDetailScreen,
    exact: true
  },
  {
    path: "/new",
    component: RaiseIssueScreen,
    exact: true
  }
];

export default routes;
