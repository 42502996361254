import React, { Component, Suspense, Props } from 'react';
import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ScrollToTop from './hoc/scrollTopOnRouteChange'

import ErrorBoundary from './hoc/errorBoundary';
import Alert from './hoc/alert';
import routes from './routes';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollTopOnRouteChange from './hoc/scrollTopOnRouteChange';
import { loginSuccess } from './store/actions/login';
import SuspenseLoader from './components/SuspenseLoader';
import initStore from './store/initStore';

import './css/font.css';
import './css/svg-style.css';

const store = initStore();

const getQueryStringValue = (key: string) => {
  return decodeURIComponent(
    window.location.search.replace(
      new RegExp(
        '^(?:.*[&\\?]' +
        encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') +
        '(?:\\=([^&]*))?)?.*$',
        'i'
      ),
      '$1'
    )
  );
};

import(`./css/${getQueryStringValue('theme') || 'blue'}.scss`);

class App extends Component<any, any> {
  // scrollableView: any;
  constructor(props: {}) {
    super(props);
    // this.scrollableView = React.createRef();
    const user = localStorage.getItem('user');
    if (user) {
      store.dispatch(loginSuccess({ user: JSON.parse(user) }));
    }
    this.state = {
      menuVisible: false
    };
  }

  toggleMenuVisibility = () => {
    this.setState({ menuVisible: !this.state.menuVisible });
  };

  hideMenu = () => {
    this.setState({ menuVisible: false });
  };

  // scrollTop = () => {
  //   // we dont use the default document scroll view, instead we use root__content
  //   // this function lets us scroll to the top of that view
  //   if (this.scrollableView && this.scrollableView.current) {
  //     this.scrollableView.current.scrollTo(0, 0);
  //   }
  // };

  // componentDidMount() {
  //   this.props.history.listen((location, action) => {
  //     window.scrollTop(0, 0);
  //   });
  // }

  render() {
    return (
      <Provider store={store}>
        <ErrorBoundary>
          <Suspense fallback={<SuspenseLoader />}>
            <BrowserRouter>
              <ScrollToTop>
                <Alert />
                <Switch>
                  {routes.map((route, i) => (
                    <Route key={i} {...route} />
                  ))}
                </Switch>
              </ScrollToTop>
            </BrowserRouter>
          </Suspense>
        </ErrorBoundary>
      </Provider>
    );
  }
}

export default App;
