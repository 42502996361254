import { makePostRequest } from "./httpHelperFetch";

import { LoginResponsePaylaod, LoginPayloadType } from "./apiPayload";

export const logIn = async (loginPayload: LoginPayloadType) => {
  try {
    const baseUrl = process.env.REACT_APP_DOMAIN;
    return await makePostRequest<LoginResponsePaylaod>(
      `${baseUrl}/account`,
      loginPayload
    );
  } catch (e) {
    console.log(e);
  }
  return [];
};
