import { handleActions } from "redux-actions";

import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN
} from "../../constants/login";

export const initialState = {
  user: {},
  errorMessage: "",
  isFetching: false
};

export default handleActions(
  {
    [LOGIN]: (state, { }) => ({
      ...state,
      isFetching: true
    }),
    [LOGIN_SUCCESS]: (state, { payload: { user } }) => ({
      ...state,
      user,
      isFetching: false
    }),
    [LOGIN_ERROR]: (state, { payload: { errorMessage } }) => ({
      ...state,
      errorMessage,
      isFetching: false
    })
  },
  initialState
);
