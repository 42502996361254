import { takeEvery, put, call } from "redux-saga/effects";
import { AnyAction } from "redux";

import { fetchByReferenceCode, createIssue, fetchIssues } from "../../../lib/issueApiHelper";

import { fetchReferenceCodeSuccess, fetchReferenceCodeError, raiseIssueSuccess, raiseIssueError, fetchIssuesSuccess, fetchIssuesError } from "../../actions/issue";
import { showAlert } from "../../actions/alert";

import { FETCH_REFERENCE_CODE, REFETCH_REFERENCE_CODE, RAISE_ISSUE, FETCH_ISSUES } from "../../constants/issue";

function* fetchReferenceCode({ payload: { referenceCode, history, search } }: AnyAction) {
  const issuePayload = yield call(fetchByReferenceCode, referenceCode);

  if (!issuePayload.message) {
    const { data } = issuePayload;

    yield put(
      fetchReferenceCodeSuccess({
        issueInfo: data
      })
    );
    if (history) {
      yield call(history.push, { pathname: `/issue/${data.uuid}`, search });
    }
  } else {
    if (issuePayload.status === 401) {
      if (history) {
        yield call(history.push, { pathname: "/logout", search });
      }
    }
    yield put(fetchReferenceCodeError({ errorMessage: issuePayload.message }));
    yield put(showAlert({ message: issuePayload.message, type: "danger" }));
  }
}

function* raiseIssue({ payload: { issue, history, search } }: AnyAction) {
  const issuePayload = yield call(createIssue, issue);

  if (!issuePayload.message) {
    const { data } = issuePayload;

    yield put(
      raiseIssueSuccess({
        issueInfo: data
      })
    );
    if (history) {
      yield call(history.push, { pathname: `/issue/${data.uuid}`, search });
      yield put(showAlert({ message: "Issue successfully raised.", type: "success" }));
    }
  } else {
    if (issuePayload.status === 401) {
      if (history) {
        yield call(history.push, { pathname: "/logout", search });
      }
    }
    yield put(raiseIssueError({ errorMessage: issuePayload.message }));
    yield put(showAlert({ message: issuePayload.message, type: "danger" }));
  }
}

function* fetchAllIssues({ payload: { queryPayload, history, search } }: AnyAction) {
  const issuesPayload = yield call(fetchIssues, queryPayload);

  if (!issuesPayload.message) {
    const { data, filters, meta } = issuesPayload;

    yield put(
      fetchIssuesSuccess({
        issues: data,
        filters,
        meta
      })
    );
  } else {
    if (issuesPayload.status === 401) {
      if (history) {
        yield call(history.push, { pathname: "/logout", search });
      }
    }
    yield put(fetchIssuesError({ errorMessage: issuesPayload.message }));
    yield put(showAlert({ message: issuesPayload.message, type: "danger" }));
  }
}

export default function* watchSystem() {
  yield takeEvery(FETCH_REFERENCE_CODE, fetchReferenceCode);
  yield takeEvery(REFETCH_REFERENCE_CODE, fetchReferenceCode);
  yield takeEvery(RAISE_ISSUE, raiseIssue);
  yield takeEvery(FETCH_ISSUES, fetchAllIssues);
}
