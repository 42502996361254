import { makeGetRequest } from './httpHelperFetch';
import { PropertyResponse } from './apiPayload';

export const searchProperties = async (search?: string) => {
  try {
    const baseUrl = process.env.REACT_APP_DOMAIN;
    let url = `${baseUrl}/properties/`;
    if (search) {
      url = `https://api.estatelogs.com/3/properties/?searchField=addressLine1&searchField=addressLine2&searchField=streetName&searchField=region&searchField=streetNumber&searchField=aliases&searchField=areacode&searchField=name&issuePortalToken=f0f466c5ea7c4270b2161ff964404f09&search=${search}`;
    }
    return await makeGetRequest<PropertyResponse>(url);
  } catch (e) {
    console.log(e);
    return { message: e.message };
  }
};
