import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { showAlert } from "../store/actions/alert";

import { AlertMessage } from "../types/alert";

interface IProps {
    showAlert: (payload: object) => void;
    alert: AlertMessage;
}

class Alert extends PureComponent<IProps> {

    resetAlert = () => {
        setTimeout(() => {
            this.props.showAlert({ message: "", type: "" });
        }, 5000);
    }

    render() {
        // const { children, ...restProps } = this.props as {
        //     children: React.ReactNode;
        // };
        const { alert } = this.props;
        const { message, type } = alert;

        if (message) {
            this.resetAlert();
            return (
                <div style={{ position: "fixed", width: "100%", top: 0, zIndex: 10 }}>
                    <div className={`alert alert-${type}`} role="alert">
                        {message}
                    </div>
                </div>
            );
        }

        return <React.Fragment />;
    }
}

const mapDispatchtoProps = { showAlert };

const mapStateToProps = ({ alert }: { alert: AlertMessage }) => ({
    alert
});

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(Alert);
