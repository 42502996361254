import { takeEvery, put, call } from "redux-saga/effects";
import { AnyAction } from "redux";

import { logIn } from "../../../lib/loginApiHelper";

import { showAlert } from "../../actions/alert";
import { loginSuccess, loginError } from "../../actions/login";

import { LOGIN } from "../../constants/login";

function* login({ payload: { loginPayload, history, search } }: AnyAction) {
    const loginResponse = yield call(logIn, loginPayload);
    if (!loginResponse.message) {
        const { data } = loginResponse;

        yield put(
            loginSuccess({
                user: data
            })
        );
        localStorage.setItem("user", JSON.stringify(data));
        if (history) {
            yield call(history.push, { pathname: "/", search });
        }
    } else {
        yield put(showAlert({ message: loginResponse.message, type: "danger" }));
        yield put(
            loginError({
                errorMessage: loginResponse.message
            })
        );
    }
}

export default function* watchSystem() {
    yield takeEvery(LOGIN, login);
}
