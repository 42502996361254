import { handleActions } from "redux-actions";

import {
  FETCH_REFERENCE_CODE,
  REFETCH_REFERENCE_CODE,
  FETCH_REFERENCE_CODE_SUCCESS,
  FETCH_REFERENCE_CODE_ERROR,
  FETCH_ISSUES,
  FETCH_ISSUES_SUCCESS,
  FETCH_ISSUES_ERROR,
  FETCH_ISSUE_SUBSCRIPTION_SUCCESS,
  UPDATE_COMMENT,
  UPDATE_COMMENT_ERROR,
  DELETE_COMMENT,
  DELETE_COMMENT_ERROR,
  DELETE_COMMENT_SUCCESS,
  RAISE_ISSUE,
  RAISE_ISSUE_SUCCESS,
  RAISE_ISSUE_ERROR
} from "../../constants/issue";

export const initialState = {
  issueInfo: {},
  issues: [],
  filters: {},
  meta: {},
  errorMessage: "",
  commentErrorMessage: "",
  isFetching: false
};

export default handleActions(
  {
    [FETCH_REFERENCE_CODE]: (state, { }) => ({
      ...state,
      issueInfo: {},
      isFetching: true
    }),
    [REFETCH_REFERENCE_CODE]: (state, { }) => ({
      ...state,
      isFetching: true
    }),
    [FETCH_REFERENCE_CODE_SUCCESS]: (state, { payload: { issueInfo } }) => ({
      ...state,
      issueInfo,
      isFetching: false
    }),
    [FETCH_REFERENCE_CODE_ERROR]: (state, { payload: { errorMessage } }) => ({
      ...state,
      errorMessage,
      isFetching: false
    }),
    [FETCH_ISSUES]: (state, { }) => ({
      ...state,
      isFetching: true
    }),
    [FETCH_ISSUES_SUCCESS]: (state, { payload: { issues, filters, meta } }) => ({
      ...state,
      issues,
      filters: filters ? filters : state.filters,
      meta,
      isFetching: false
    }),
    [FETCH_ISSUES_ERROR]: (state, { payload: { errorMessage } }) => ({
      ...state,
      errorMessage,
      isFetching: false
    }),
    [FETCH_ISSUE_SUBSCRIPTION_SUCCESS]: (state, { payload: issue }) =>  ({
      ...state,
      issueInfo: { ...state.issueInfo, ...issue }
    }),
    [UPDATE_COMMENT]: (state, { }) => ({
      ...state,
      isFetching: true
    }),
    [UPDATE_COMMENT_ERROR]: (state, { payload: { errorMessage } }) => ({
      ...state,
      commentErrorMessage: errorMessage,
      isFetching: false
    }),
    [DELETE_COMMENT]: (state, { }) => ({
      ...state,
      isFetching: true
    }),
    [DELETE_COMMENT_SUCCESS]: (state, { }) => ({
      ...state,
      isFetching: false
    }),
    [DELETE_COMMENT_ERROR]: (state, { payload: { errorMessage } }) => ({
      ...state,
      errorMessage,
      isFetching: false
    }),
    [RAISE_ISSUE]: (state, { }) => ({
      ...state,
      isFetching: true
    }),
    [RAISE_ISSUE_SUCCESS]: (state, { }) => ({
      ...state,
      isFetching: false
    }),
    [RAISE_ISSUE_ERROR]: (state, { payload: { errorMessage } }) => ({
      ...state,
      errorMessage,
      isFetching: false
    }),
  },
  initialState
);
