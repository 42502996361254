import { handleActions } from "redux-actions";

import {
  SHOW_ALERT
} from "../../constants/alert";

export const initialState = {
  message: "",
  type: "success"
};

export default handleActions(
  {
    [SHOW_ALERT]: (state, { payload: { message, type } }) => ({
      ...state,
      message,
      type
    })
  },
  initialState
);
