export const FETCH_REFERENCE_CODE_SUCCESS = "FETCH_REFERENCE_CODE_SUCCESS";
export const FETCH_REFERENCE_CODE = "FETCH_REFERENCE_CODE";
export const REFETCH_REFERENCE_CODE = "REFETCH_REFERENCE_CODE";
export const FETCH_REFERENCE_CODE_ERROR = "FETCH_REFERENCE_CODE_ERROR";

export const FETCH_ISSUE_SUBSCRIPTION_SUCCESS = "FETCH_ISSUE_SUBSCRIPTION_SUCCESS";

export const CREATE_COMMENT = "CREATE_COMMENT";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const UPDATE_COMMENT_ERROR = "UPDATE_COMMENT_ERROR";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";

export const RAISE_ISSUE_SUCCESS = "RAISE_ISSUE_SUCCESS";
export const RAISE_ISSUE = "RAISE_ISSUE";
export const RAISE_ISSUE_ERROR = "RAISE_ISSUE_ERROR";

export const FETCH_ISSUES_SUCCESS = "FETCH_ISSUES_SUCCESS";
export const FETCH_ISSUES = "FETCH_ISSUES";
export const FETCH_ISSUES_ERROR = "FETCH_ISSUES_ERROR";
