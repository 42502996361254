import "isomorphic-fetch";

export async function makePostRequest<T>(
  url: string,
  payLoad: any,
  isFormData?: boolean,
): Promise<T> {
  const headers: any = {
    Accept: "application/json, application/xml, text/plain, text/html, *.*"
  };

  if (isFormData) {
    headers["content-type"] = "multipart/form-data";
  } else {
    headers["content-type"] = "application/json";
  }

  const user = localStorage.getItem("user");

  if (user) {
    const userObj = JSON.parse(user);
    const authKey = "Authorization";
    headers[authKey] = `Token ${userObj.authToken}`;
  }

  try {
    const response = await fetch(url, {
      method: "POST",
      headers,
      body: isFormData ? payLoad : JSON.stringify(payLoad)
    });
    const json = await response.json();
    return { status: response.status, ...json };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function makeGetRequest<T>(
  url: string
): Promise<T> {
  const headers: any = {
    Accept: "application/json, application/xml, text/plain, text/html, *.*"
  };
  headers["content-type"] = "application/json";

  const user = localStorage.getItem("user");
  if (user) {
    const userObj = JSON.parse(user);
    const authKey = "Authorization";
    headers[authKey] = `Token ${userObj.authToken}`;
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers
    });
    const json = await response.json();
    return { status: response.status, ...json };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function makePatchRequest<T>(
  url: string,
  payLoad: any,
  isFormData?: boolean,
): Promise<T> {
  const headers: any = {
    Accept: "application/json, application/xml, text/plain, text/html, *.*"
  };
  headers["content-type"] = "application/json";
  if (isFormData) {
    delete headers["content-type"];
  }

  const user = localStorage.getItem("user");
  if (user) {
    const userObj = JSON.parse(user);
    const authKey = "Authorization";
    headers[authKey] = `Token ${userObj.authToken}`;
  }

  try {
    const response = await fetch(url, {
      method: "PATCH",
      headers,
      body: isFormData ? payLoad : JSON.stringify(payLoad)
    });
    // const json = await response.json();
    return { status: response.status } as any;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function makeDeleteRequest<T>(
  url: string
): Promise<T> {
  const headers: any = {
    Accept: "application/json, application/xml, text/plain, text/html, *.*"
  };
  headers["content-type"] = "application/json";

  const user = localStorage.getItem("user");
  if (user) {
    const userObj = JSON.parse(user);
    const authKey = "Authorization";
    headers[authKey] = `Token ${userObj.authToken}`;
  }

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers,
    });
    return { status: response.status } as any;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
