import { createActions } from "redux-actions";

import * as constants from "../constants/property";

export const {
    fetchProperties,
    fetchPropertiesSuccess,
    fetchPropertiesError
} = createActions(
    constants.FETCH_PROPERTIES,
    constants.FETCH_PROPERTIES_SUCCESS,
    constants.FETCH_PROPERTIES_ERROR,
);
