import { takeEvery, put, call } from "redux-saga/effects";
import { AnyAction } from "redux";

import { searchProperties } from "../../../lib/propertyApiHelper";

import { fetchPropertiesSuccess, fetchPropertiesError } from "../../actions/property";
import { FETCH_PROPERTIES } from "../../constants/property";

function* searchProperty({ payload: { search, history, query } }: AnyAction) {
    const searchPayload = yield call(searchProperties, search);

    if (!searchPayload.message) {
        const { data, meta } = searchPayload;

        yield put(
            fetchPropertiesSuccess({
                properties: data,
                propertiesMeta: meta
            })
        );
    } else {
        if (searchPayload.status === 401) {
            if (history) {
                yield call(history.push, { pathname: "/logout", search: query });
            }
        }
        yield put(fetchPropertiesError({ errorMessage: searchPayload.message }));
    }
}

export default function* watchSystem() {
    yield takeEvery(FETCH_PROPERTIES, searchProperty);
}
