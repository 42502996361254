import { handleActions } from "redux-actions";

import {
    FETCH_PROPERTIES_SUCCESS,
    FETCH_PROPERTIES_ERROR,
} from "../../constants/property";

export const initialState = {
    properties: [],
    propertiesMeta: {},
    errorMessage: ""
};

export default handleActions(
    {
        [FETCH_PROPERTIES_SUCCESS]: (state, { payload: { properties, propertiesMeta } }) => ({
            ...state,
            properties,
            propertiesMeta
        }),
        [FETCH_PROPERTIES_ERROR]: (state, { payload: { errorMessage } }) => ({
            ...state,
            errorMessage
        })
    },
    initialState
);
