import { makeGetRequest, makePostRequest, makePatchRequest, makeDeleteRequest } from "./httpHelperFetch";
import { IssueReferenceResponse, IssueRequestPayload, IssuesResponse } from "./apiPayload";

import { CommentPayload } from "../types/comment";
import { IssuePayloadType } from "../types/issue";

export const fetchByReferenceCode = async (referenceCode: string) => {
  try {
    return await makeGetRequest<IssueReferenceResponse>(
      `https://v1.api.staging.estatelogs.com/service-requests/${referenceCode}`
    );
  } catch (e) {
    console.log(e);
    return { message: e.message };
  }
};

export const postComment = async (issueId: string, comment: CommentPayload) => {
  try {
    const payload: any = {
      "serviceRequest": issueId
    }
    if (comment.body) {
      payload['body'] = comment.body
      payload['name'] = comment.name
      payload['contactInformation'] = comment.contactInformation
    }
    return await makePostRequest<IssueReferenceResponse>(
      `https://v1.api.staging.estatelogs.com/service-requests/comments/`,
      payload
    );
  } catch (e) {
    console.log(e);
    return { message: e.message };
  }
};

export const updateComment = async (commentId: string, comment: CommentPayload) => {
  try {
    const payload: any = {}
    if (comment.body) {
      payload['body'] = comment.body
    }
    return await makePatchRequest<{ status: number }>(
      `https://v1.api.staging.estatelogs.com/service-requests/comments/${commentId}`,
      payload
    );
  } catch (e) {
    console.log(e);
    return { message: e.message };
  }
};

export const deleteComment = async (commentId: string) => {
  try {
    return await makeDeleteRequest<{ status: number }>(
      `https://v1.api.staging.estatelogs.com/service-requests/comments/${commentId}`
    );
  } catch (e) {
    console.log(e);
    return { message: e.message };
  }
};

export const createIssue = async (issue: IssuePayloadType) => {
  try {
    const payload: any = {}

    if (issue && issue.sendEmailToSubmitter) {
      payload['submitterSendTrackingInformation'] = true
    }

    if (issue && issue.petsInformation) {
      payload['petsInformation'] = issue.petsInformation
    }

    if (issue && issue.petsPresent) {
      if (issue.petsPresent === 'yes') {
        payload['petsPresent'] = true
      } else if (issue.petsPresent === 'no') {
        payload['petsPresent'] = false
      }
    }

    if (issue && issue.submitterName) {
      payload['submitterName'] = issue.submitterName
    }

    if (issue && issue.submitterTelephoneNumber) {
      payload['submitterTelephone'] = issue.submitterTelephoneNumber
    }

    if (issue && issue.submitterContactInformation) {
      payload['submitterEmail'] = issue.submitterContactInformation
    }

    if (issue && issue.address) {
      payload['address'] = issue.address
    }

    if (issue && issue.description) {
      payload['description'] = issue.description
    }

    if (issue && issue.technicianVisitInstructions) {
      payload['visitInstructions'] = issue.technicianVisitInstructions
    }

    if (issue && issue.entryByKeyAllowed) {
      if (issue.entryByKeyAllowed === 'yes') {
        payload['unattendedEntryPermitted'] = true
      } else if (issue.entryByKeyAllowed === 'no') {
        payload['unattendedEntryPermitted'] = false
      }
    }

    if (issue && issue.location) {
      payload['location'] = issue.location
    }

    if (issue && issue.property) {
      payload['property'] = issue.property
    }

    // save the SR
    const response = await makePostRequest<IssueReferenceResponse>(
      `https://v1.api.staging.estatelogs.com/service-requests/`,
      payload
    );

    if (response.data && response.data.uuid && issue && issue.files) {
      const issueUuid = response.data.uuid;

      await Promise.all(issue.files.map(async (file) => {
        const formData = new FormData();
        formData.append(`file`, file);
        formData.append(`filename`, file.name);
        formData.append(`serviceRequest`, issueUuid);

        return await fetch(`https://v1.api.staging.estatelogs.com/files/`, {
          "headers": {
            'Accept': `application/json`
          },
          "method": `POST`,
          "body": formData
        });
      }));
    }

    return response
  } catch (e) {
    console.log(e);
    return { message: e.message };
  }
};

// https://api.estatelogs.com/3/issues/?status=pending&status=unassigned&status=accepted&pageSize=8&page=1&role=manager&include=filters&createdDatetimeStart=2018-02-01&createdDatetimeEnd=2018-06-01

export const fetchIssues = async (payload: IssueRequestPayload) => {
  try {
    const baseUrl = process.env.REACT_APP_DOMAIN;
    let url = `${baseUrl}/issues/?`;
    const { status, pageSize, page, role, include, createdDatetimeEnd, createdDatetimeStart, completedDatetimeEnd, completedDatetimeStart, order, property, createdByUser } = payload;

    url = `${url}pageSize=${pageSize || 10}&page=${page || 1}&`;

    if (status) {
      status.forEach(s => url = `${url}status=${s}&`);
    }

    if (role) {
      role.forEach(r => url = `${url}role=${r}&`);
    }

    if (include) {
      include.forEach(i => url = `${url}include=${i}&`);
    }

    if (createdDatetimeEnd) {
      url = `${url}createdDatetimeEnd=${createdDatetimeEnd}&`;
    }

    if (createdDatetimeStart) {
      url = `${url}createdDatetimeStart=${createdDatetimeStart}&`;
    }

    if (completedDatetimeEnd) {
      url = `${url}completedDatetimeEnd=${completedDatetimeEnd}&`;
    }

    if (completedDatetimeStart) {
      url = `${url}completedDatetimeStart=${completedDatetimeStart}&`;
    }

    if (order) {
      url = `${url}order=${order}&`;
    }

    if (property) {
      property.forEach(p => url = `${url}property=${p}&`);
    }

    if (createdByUser) {
      createdByUser.forEach(c => url = `${url}createdByUser=${c}&`);
    }

    return await makeGetRequest<IssuesResponse>(
      url
    );
  } catch (e) {
    console.log(e);
    return { message: e.message };
  }
};
