import { combineReducers } from "redux";

import issue from "./issue/issue";
import alert from "./alert/alert";
import property from "./property/property";
import login from "./login/login";

export default combineReducers({
  issue,
  alert,
  property,
  login
});
