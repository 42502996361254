import { all } from "redux-saga/effects";

import issueSaga from "./issue/issue";
import commentSaga from "./issue/comment";
import propertySaga from "./property/property";
import loginSaga from "./login/login";

export default function* rootSaga() {
  yield all([issueSaga(), commentSaga(), propertySaga(), loginSaga()]);
}
