import { createActions } from "redux-actions";

import * as constants from "../constants/issue";

export const {
  fetchReferenceCode,
  fetchReferenceCodeSuccess,
  fetchReferenceCodeError,
  refetchReferenceCode,
  createComment,
  raiseIssue,
  raiseIssueSuccess,
  raiseIssueError,
  fetchIssues,
  fetchIssuesSuccess,
  fetchIssuesError,
  fetchIssueSubscriptionSuccess,
  updateComment,
  updateCommentError,
  deleteComment,
  deleteCommentSuccess,
  deleteCommentError
} = createActions(
  constants.FETCH_REFERENCE_CODE,
  constants.FETCH_REFERENCE_CODE_SUCCESS,
  constants.FETCH_REFERENCE_CODE_ERROR,
  constants.REFETCH_REFERENCE_CODE,
  constants.CREATE_COMMENT,
  constants.RAISE_ISSUE,
  constants.RAISE_ISSUE_SUCCESS,
  constants.RAISE_ISSUE_ERROR,
  constants.FETCH_ISSUES,
  constants.FETCH_ISSUES_SUCCESS,
  constants.FETCH_ISSUES_ERROR,
  constants.FETCH_ISSUE_SUBSCRIPTION_SUCCESS,
  constants.UPDATE_COMMENT,
  constants.UPDATE_COMMENT_ERROR,
  constants.DELETE_COMMENT,
  constants.DELETE_COMMENT_SUCCESS,
  constants.DELETE_COMMENT_ERROR,
);
